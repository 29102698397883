import React from 'react';
import {navigate} from 'gatsby';

import AccountForm from './accountForm';
import {Translate} from '../../components/translations';
import Account from '../../components/accountWrapper';
import UserWrap from '../../components/userWrap';

const UpdateUser = (): JSX.Element => {
  return (
    <Account title={<Translate name="UPDATE_INFORMATION" />}>
      <UserWrap
        withLoading={() => <></>}
        withoutUser={() => {
          navigate('/#login', {state: {goBack: true}});
          return <></>;
        }}
        withUser={user => <AccountForm user={user} />}
      />
    </Account>
  );
};

export default UpdateUser;
